import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link, useLocation } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import useAuth from "hooks/useAuth";
import { options } from "toastr";
import useMenu from "hooks/useMenu";

import { useSelector } from "react-redux";
function refreshSidebar() {
  const data = useSelector(state => {
    const initialData = state.data
    const filteredData = expensiveFiltering(initialData)
    const sortedData = expensiveSorting(filteredData)
    const transformedData = expensiveTransformation(sortedData)

    return transformedData
  })
}
const SidebarContent = props => {
  const location = useLocation();
  const ref = useRef();
  const path = location.pathname;
  const { auth } = useAuth();
  const [pickupCount, setPickupCount] = useState(0);
  const [lUCOHoldCount,setlUCoHoldCount] = useState(0);
  const [menus, setMenus] = useState([]);
  //untuk reload jika menu berubah
  const refreshSidebar = useSelector(state => {
    const initialData = state.Menu.value
    return initialData
  })
  const userId = auth.auth._id

  const axiosApi = useAxiosPrivate();


  useEffect(() => {
    getMenuData()
  }, []);

  const pickupCountText = () => {
    if (pickupCount > 0) {
      return <span style={{ color: 'red' }}>({pickupCount})</span>
    }

  }

  const lUCOHoldCountText = () => {
    if (lUCOHoldCount > 0) {
      return <span style={{ color: 'red' }}>({lUCOHoldCount})</span>
    }
  }



  const lUCOHoldDot = () => {
    if (lUCOHoldCount > 0) {
      return "red-dot"
    }
  }
  const pickupDot = () => {
    if (pickupCount > 0) {
      return "red-dot"
    }
  }
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = location.pathname;
    const fullPath = pathName;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");

    removeActivation(items);
    let basePath = fullPath.split('/')[1];
    if(basePath==""){
      basePath = 'dashboard'
    }
    const result = `/${basePath}`;

    for (let i = 0; i < items.length; ++i) {
      if (result === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path, activateParentDropdown]);

  const socket = new WebSocket(`${process.env.REACT_APP_WS_URL}/websockets?pickup-status=unfinished`);

  socket.addEventListener('open', function (event) {
    // console.log('WebSocket connection established.');
    // window.alert('WebSocket connection established.');
  });
  socket.addEventListener('close', function (event) {
    // console.log('WebSocket connection closed.');
    // window.alert('WebSocket connection closed.');

  });
  useEffect(() => {
    socket.addEventListener('message', function (event) {
      const response = JSON.parse(event.data);
      // console.log(response);

      if (response.popup) {
        if (!window.Notification) {
          window.alert('A new pickup request:' + response.data.pickup_id);
          console.log('Browser does not support notifications.');
        } else {
          // check if permission is already granted
          if (Notification.permission === 'granted') {
            // show notification here
            var notify = new Notification('New Pickup Request!', { body: "Pick up ID:" + response.data.pickup_id, icon: "https://www.noovoleum.com/uploads/attachments/cllw2vim200eqbjl5gnlv36ii-noovoleum-logo.svg" });
          } else {
            // request permission from user
            Notification.requestPermission().then(function (p) {
              if (p === 'granted') {
                var notify = new Notification('New Pickup Request!', { body: "Pick up ID:" + response.data.pickup_id, icon: "https://www.noovoleum.com/uploads/attachments/cllw2vim200eqbjl5gnlv36ii-noovoleum-logo.svg" });
              } else {
                window.alert('A new pickup request:' + response.data.pickup_id);
                console.log('User blocked notifications.');
              }
            }).catch(function (err) {
              console.error(err);
            });
          }
        }
      }
      updatePickupCount();
    });

    // return () => response;

    // const interval = setInterval( () => {
    //   // Your fabulous code that needs to happen every 10 seconds goes here!
    //   updatePickupCount();
    // }, 10000);

    // // Cleanup function to avoid chaos when component unmounts
    // return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    ref.current.recalculate();
    updatePickupCount();
  }, []);



  useEffect(() => {
    new MetisMenu("#side-menu");

    activeMenu();
    if (window.Notification) {
      if (Notification.permission !== 'granted') {
        // request permission from user
        Notification.requestPermission().then(function (p) {
        }).catch(function (err) {
          console.error(err);
        });
      }
    }
  }, [menus]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      // Your fabulous code that needs to happen every 15 seconds goes here!
      updatePickupCount();
    }, 15000);

    // Cleanup function to avoid chaos when component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array means it runs once on mount

  const updatePickupCount = async () => {
    try {
      const response = await axiosApi.get(`/api/admin/pickupstatus`);
      const result = response.data.result;
      setPickupCount(result.pickupCount);
      setlUCoHoldCount(result.lUCOHoldCount);
    } catch (error) {
      console.log(error);
    }
  }
  const getMenuData = async () => {
    try {
      const response = await axiosApi.get(`/api/admin/menu/active/${userId}`);
      const result = response.data.result;
      setMenus(result);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {
              menus.map(menu => (
                menu.submenu && menu.submenu.length > 0 ?
                  <li key={menu._id}>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className={menu.icon}></i>
                      <span className={menu.title == 'Support' ? pickupDot() : (menu.title =="Engineering" ? lUCOHoldDot() : "")} >{props.t(menu.title)}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      {menu.submenu.map(submenu => (
                        <li key={submenu._id}>
                          <Link to={'/' + submenu.url}>{props.t(submenu.title)} {submenu.title == 'Pickups' ? pickupCountText() : (submenu.title == "Deployments" ? lUCOHoldCountText() : "")} </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  :
                  <li key={menu._id}>
                    <Link to={'/' + menu.url} className="waves-effect">
                      <i className={menu.icon}></i>
                      <span>{props.t(menu.title)}</span>
                    </Link>
                  </li>
              ))
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
