import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Input, Row, Col, Form, FormFeedback } from "reactstrap";
import InputMask from "react-input-mask";
import logolightImg from "../../../../assets/images/noov-green.svg";

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import DataTables from "./DataTable";
const OrderTracking = () => {
    document.title = "Order | Trace";
    const [defaultId, setDefaultId] = useState(null);
    const [isSearch, setIsSearch] = useState(false);

    const [datas, setDatas] = useState();
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    // const [totalRecords, setTotalRecords] = useState(0);
    const [lazyState, setlazyState] = useState({
        searchText: '',
        config: {
            first: 0,
            rows: 5,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: { value: '', matchMode: 'contains' },
            },
        }
    });

    const handleIdChange = (event) => {
        let { name, value } = event.target;
        const uppercaseValue = value ? value.toUpperCase() : '';

        setDefaultId(uppercaseValue);
        validation.setFieldValue(name, uppercaseValue); // Update formik's value

    };
    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            order_code: defaultId ?? '',

        },
        validationSchema: Yup.object().shape({
            order_code: Yup.string().required("This value is required"),

        }),
        onSubmit: async (values, { resetForm }) => {
            try {

                
                setIsSearch(true)
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data)
                } else {
                    console.error(error)
                }
                setIsSearch(false)
            }
        },
    })
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="content-center ">
                    <Container fluid>
                        <Row className="justify-content-center">
                            <Col lg={10}>
                                <div className=" text-center">
                                    <img src={logolightImg} alt="logo" height="70" />
                                    <h3 className="mt-4">Order Trace</h3>
                                    <p className="">
                                        Track the UCO Units based on Orders
                                    </p>
                                    <div className="text-center coming-soon-search-form pt-4">
                                        <Form
                                            onSubmit={e => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                                setIsSearch(false)
                                                return false
                                            }}
                                        >
                                            <div className="mb-3">
                                                <InputMask
                                                    placeholder="Enter Order ID"
                                                    name="order_code"
                                                    mask="aaaa-aa-aa-99999"  // Mask for date format
                                                    value={defaultId}  // Controlled input value
                                                    onChange={handleIdChange}  // Update state on change
                                                    maskChar={null}  // Removes default mask characters (like "_")
                                                >
                                                    {(inputProps) => <Input {...inputProps} id="masked-input" autoComplete="off" type="text" />}
                                                </InputMask>
                                            </div>

                                            {validation.touched.order_code && validation.errors.order_code ? (
                                                <div type="invalid" style={{ color: 'red' }} >Enter Your Order ID</div>
                                            ) : null}
                                            <Button type="submit" color="primary">
                                                <span><i className="fa fa-search"></i></span>
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {isSearch && <DataTables order_code={defaultId} setmodal_center={setIsSearch} modal_center={isSearch} />}

                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default OrderTracking;
