import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText } from "reactstrap"
import { Dropdown } from "primereact/dropdown"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { post, del, get, put } from "../../../../../helpers/api_helper";
import useAdmin from "hooks/useAdmin";

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import { update } from "lodash";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import InputMask from "react-input-mask";
const AddUserModal = () => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { methodList, setRefresh } = useAdmin();
    const { showToast } = useAuth();
    const [levels, setLevels] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [selectedPrefix, setSelectedPrefix] = useState("+62");
    const [prefixList, setPrefixList] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState([]);

    const [superadmin, setSuperadmin] = useState('');

    const getPermissions = async () => {
        try {
            const response = await axiosApi.get('/api/admin/permission');
            response.data.result.forEach(element => {
                if (element.title.toLowerCase() == 'superadmin')
                    setSuperadmin(element.key)
            });
            setPermissions(response.data.result);
        } catch (err) {
            console.log(err);
        }
    }
    const getCountryCodes = async () => {
        try {
            const response = await axiosApi.get('/api/v1/app/countrylist');
            var prefixes = []

            if (response.data)
                response.data.forEach(element => {
                    var prefix = {
                        ...element,
                        value: element.phonePrefix,
                    }
                    prefixes.push(prefix)
                })
            setPrefixList(prefixes);
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        if(modal_center){
            getPermissions();
            getCountryCodes();
        }
    }, [modal_center]);

    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        setPhoneNumber(null);
        setSelectedPrefix("+62");
        setSelectedMethod([]);
        validation.resetForm();
    };
    const updateMethods = (value) => {
        
        setSelectedMethod(prevLevels => {
            if (!prevLevels.includes(value)) {
                return [...prevLevels, value];
            } else {
                return prevLevels.filter(level => level !== value);
            }
        });
    };
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const handlePhoneNumber = (e) => {
        let { name, value } = event.target;
        if (value.startsWith("0")) {
            value = value.slice(1); // Remove the first character
        }

        setPhoneNumber(value);
        validation.setFieldValue(name, value); // Update formik's value
    }
    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: '',
            username: '',
            email: '',
            password: '',
            phone_number: ''
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(
                "This value is required"
            ),
            username: Yup.string().required(
                "This value is required"
            ),
            password: Yup.string().required(
                "This value is required"
            ),
            email: Yup.string()
                .email("Must be a valid Email")
                .max(255)
                .required("Email is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                var levelText = levels.join(","); // You can use any delimiter you prefer, e.g., ", " or " "

                const opts = { ...values, level: levelText, phone_number: phoneNumber ? selectedPrefix + " " + phoneNumber : "", notification_enabled_list: selectedMethod }
                
                const response = await post('/api/admin/register', opts);
                setRefresh(response.random);
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }
            }
        }
    });
    // Function to update the level field
    const updateLevel = (value) => {
        setLevels(prevLevels => {
            if (!prevLevels.includes(value)) {
                return [...prevLevels, value];
            } else {
                return prevLevels.filter(level => level !== value);
            }
        });
    };
    const changePrefix = (e) => {
        setSelectedPrefix(e.value);
    };
    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Add New Dashboard User </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Add New Dashboard User</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div className="mb-3">
                            <Label className="form-label">Name</Label>
                            <Input
                                name="name"
                                placeholder="Enter Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Username</Label>
                            <Input
                                name="username"
                                placeholder="Enter Username"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                    validation.touched.username && validation.errors.username ? true : false
                                }
                            />
                            {validation.touched.username && validation.errors.username ? (
                                <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Phone Number */}
                        <div className="mb-3">
                            <Label className="form-label">Phone Number</Label>
                            <div className="d-flex">
                                <Dropdown value={selectedPrefix} onChange={changePrefix} options={prefixList} optionLabel="name" className="h-1 payment-status-dropdown me-1" />
                                <InputGroup className={validation.touched.phone_number && validation.errors.phone_number ? 'is-invalid' : ''}>
                                    <InputGroupText>
                                        {selectedPrefix}
                                    </InputGroupText>
                                    <InputMask
                                        placeholder="Enter Phone Number"
                                        value={phoneNumber}
                                        mask="999999999999999"  // Mask for date format
                                        onChange={handlePhoneNumber}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.phone_number && validation.errors.phone_number ? true : false
                                        }
                                        maskChar={null}  // Removes default mask characters (like "_")
                                    >
                                        {(inputProps) => <Input {...inputProps} id="masked-input" type="text" />}
                                    </InputMask>
                                    {validation.touched.phone_number && validation.errors.phone_number ? (
                                        <FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
                                    ) : null}
                                </InputGroup>
                            </div>
                           
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                                name="email"
                                placeholder="Enter Valid Email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                    validation.touched.email && validation.errors.email ? true : false
                                }
                            />
                            {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Level</Label>
                            <FormGroup className="d-flex flex-row gap-3" style={{ flexWrap: 'wrap' }}>
                                {permissions?.map((permission) => (
                                    <FormGroup check key={permission.key} style={{ flex: '1 0 22%', maxWidth: '22%', boxSizing: 'border-box' }}>
                                        <Input id={permission.key} name="permission[]" type="checkbox" value={permission.key} onChange={(e) => {
                                            const clickedValue = e.target.value; // Get the value of the clicked checkbox
                                            if (clickedValue == superadmin) {
                                                const allCheckboxes = document.querySelectorAll('input[name="permission[]"]');
                                                if (e.target.checked) {
                                                    allCheckboxes.forEach((checkbox) => {
                                                        if (checkbox.value == superadmin) {
                                                            updateLevel(checkbox.value);
                                                        } else {
                                                            checkbox.disabled = true;
                                                            if (checkbox.checked) {
                                                                checkbox.checked = false;
                                                                updateLevel(checkbox.value);
                                                            }
                                                        }
                                                    });
                                                } else {
                                                    allCheckboxes.forEach((checkbox) => {
                                                        if (checkbox.value == superadmin) {
                                                            updateLevel(checkbox.value);
                                                        } else {
                                                            checkbox.disabled = false;
                                                        }
                                                    });
                                                }
                                            } else {
                                                if (e.target.checked) {
                                                    const clickedValue = e.target.value; // Get the value of the clicked checkbox
                                                    updateLevel(clickedValue);
                                                } else {
                                                    const clickedValue = e.target.value; // Get the value of the clicked checkbox
                                                    updateLevel(clickedValue);
                                                }
                                            }
                                        }} />
                                        <Label htmlFor={permission.key}>{permission.title}</Label>
                                        {validation.touched.permission && validation.errors.permission ? (
                                            <FormFeedback type="invalid">{validation.errors.permission}</FormFeedback>
                                        ) : null}
                                    </FormGroup>

                                ))}
                            </FormGroup>

                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                                name="password"
                                type="password"
                                placeholder="Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                    validation.touched.password && validation.errors.password ? true : false
                                }
                            />
                            {validation.touched.password && validation.errors.password ? (
                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Send Alerts as</Label>
                            <FormGroup className="d-flex" style={{ flexDirection: 'column' }}>
                                {methodList?.map((method) => (
                                    <FormGroup check key={method.key}>
                                        <Input id={method.key} name="method[]" type="checkbox" value={method.key} onChange={(e) => {
                                            const clickedValue = e.target.value; // Get the value of the clicked checkbox
                                            updateMethods(clickedValue);
                                        }} />
                                        <Label htmlFor={method.key}>{method.name}</Label>
                                        {validation.touched.method && validation.errors.method ? (
                                            <FormFeedback type="invalid">{validation.errors.method}</FormFeedback>
                                        ) : null}
                                    </FormGroup>

                                ))}
                            </FormGroup>

                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default AddUserModal
