import React from "react"
import { Link } from "react-router-dom"
import AddAdminModal from "./modals/AddAdminModal";

import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";

import avatar from "../../../../assets/images/users/user-blank.png";

import PropTypes from "prop-types";
import useAuth from "hooks/useAuth";

import EditAdminModal from "./modals/EditAdminModal";
import DeleteAdminModal from "./modals/DeleteAdminModal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import useAdmin from "hooks/useAdmin";
import moment from "moment";

import { inputDateTime } from "helpers/functions";

const AdminTable = () => {
    const { admins, loading } = useAdmin();
    const { auth } = useAuth();
    const items = Array.from({ length: 5 }, (v, i) => i);

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }

    const badgeStatus = (data) => {
        return (
            <span className={`badge bg-${data.status} text-t-${data.status}`}>
                {data.status === 'active' ? 'Active' : 'Not Active'}
            </span>
        )
    }

    const adminName = (data) => {
        return (
            <div>
                <img
                    src={data?.img || avatar}
                    alt=""
                    className="avatar-xs rounded-circle me-2"
                />{" "}
                {data.name}
            </div>
        )
    }
    const roleList = (data) => {
        const roles = data.level.split(',');

        // Check if 'superadmin' is present and replace with 'admin'
        if (roles.length > 0) {
            return <div>
                {
                    roles.map((role, index) => {
                        if (role === 'superadmin') {
                            roles[index] = 'admin';
                        }
                        return <div>
                            {role}
                        </div>;
                    })
                }
            </div>

        }

        return roles;
    }
    const methodList = (data) => {
        const roles = data?.notification_enabled_list??[];

        // Check if 'superadmin' is present and replace with 'admin'
        if (roles.length > 0) {
            return <div>
                {
                    roles.map((role, index) => {
                        return <div>
                            {role}
                        </div>;
                    })
                }
            </div>

        }

        return roles;
    }
    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <EditAdminModal admin={data} />
                </div>
                {!data.level.includes('superadmin') && <div>
                    <DeleteAdminModal admin={data} />
                </div>}
            </div>
        )
    }

    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'name', header: 'Name', body: adminName },
        { field: 'username', header: 'Username', body: '' },
        { field: 'email', header: 'Email', body: '' },
        { field: 'phone_number', header: 'Phone Number', body: '' },
        { field: 'joined', header: 'Joined', body: inputDateTime },
        { field: 'level', header: 'Role', body: roleList },
        { field: '', header: 'Alerts', body: methodList },
        { field: 'status', header: 'Status', body: badgeStatus },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <AddAdminModal />

                                {/* <h4 className="card-title mb-0">Users</h4> */}
                            </Col>
                            {/* <Col md={5}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Title" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col> */}
                            <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    {/* <UserExportCSV lazyState={lazyState} /> */}
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            value={loading ? items : admins}
                            stripedRows
                            size={'normal'}
                        >
                            {columns.map((col, i) => (
                                <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default AdminTable
