import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"
import React, { useEffect, useState } from "react";

// Formik validation
import * as Yup from "yup";
import { useFormik, FieldArray } from "formik";

import { post, del, get, put } from "../../../../../helpers/api_helper";

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import { update } from "lodash";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import usePermission from "hooks/usePermission";
import makeid from "helpers/random";

const AddPermissionModal = () => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { setRefresh } = usePermission();
    const { showToast } = useAuth();
    const [permissionMenus, setPermissionMenus] = useState([]);
    const [menus, setMenus] = useState([]);

    const getMenus = async () => {
        try {
            const response = await axiosApi.get('/api/admin/menu/active');
            setMenus(response.data.result);
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        if(modal_center){
            getMenus();
        }
    }, [modal_center]);

    const updatePermissionMenu = (value) => {
        setPermissionMenus(prevLevels => {
            if (!prevLevels.includes(value)) {
                return [...prevLevels, value];
            } else {
                return prevLevels.filter(level => level !== value);
            }
        });
    };
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            title: '',
            key: '',
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required(
                "This value is required"
            ),
            key: Yup.string().required(
                "This value is required"
            ),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {

                const opts = { ...values, menu: permissionMenus }

                const response = await post('/api/admin/permission', opts);
                setRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data) {
                    validation.setErrors(error.response.data);
                } else {
                    console.error(error);
                }
            }
        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Add Permission </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered>
                <ModalHeader className="mt-0" toggle={tog_center}>Add New Permission</ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}>
                        <div className="mb-3">
                            <Label className="form-label">Title</Label>
                            <Input
                                name="title"
                                placeholder="Enter Title"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                    validation.touched.title && validation.errors.title ? true : false
                                }
                            />
                            {validation.touched.title && validation.errors.title ? (
                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Key</Label>
                            <Input
                                name="key"
                                placeholder="Enter Key"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.key || ""}
                                invalid={
                                    validation.touched.key && validation.errors.key ? true : false
                                }
                            />
                            {validation.touched.key && validation.errors.key ? (
                                <FormFeedback type="invalid">{validation.errors.key}</FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <Label className="form-label">Menu List</Label>
                            <FormGroup className="d-flex" style={{ flexDirection: 'column' }}>
                                {menus?.map((menu) => (
                                    <FormGroup check key={menu._id}>
                                        <Input id={menu._id} name="menu[]" type="checkbox" value={menu._id} onChange={(e) => {
                                            const clickedValue = e.target.value; // Get the value of the clicked checkbox
                                            updatePermissionMenu(clickedValue);
                                        }} />
                                        <Label htmlFor={menu._id}>{menu.title}</Label>
                                        {validation.touched.menu && validation.errors.menu ? (
                                            <FormFeedback type="invalid">{validation.errors.menu}</FormFeedback>
                                        ) : null}
                                    </FormGroup>

                                ))}
                            </FormGroup>

                        </div>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default AddPermissionModal
