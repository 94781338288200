import { useEffect, useMemo, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup, InputGroup, InputGroupText, Row, Col, Card, CardBody } from "reactstrap"

import moment from "moment";
import useAdmin from "hooks/useAdmin";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';

import useAxiosPrivate from "hooks/useAxiosPrivate";

import { Link } from "react-router-dom";

import { simplify, startTime, endTime, debounce, statusUco } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
// import TransactionHistoryExportCSV from "../exports/TransactionHistoryExportCSV";
import useAuth from "hooks/useAuth";
import TrackingModal from "./TrackingModal";
const DataTables = ({ order_code, setmodal_center, modal_center }) => {
    const { showToast } = useAuth();
    const initialLazyState = {
        searchText: '',
        config: {
            first: 0,
            rows: 50,
            page: 0,
            sortField: null,
            sortOrder: null,
            filters: {
                name: {
                    value: '',
                    matchMode: 'contains'
                },
            }
        }
    }
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [datas, setDatas] = useState([]);
    const [search, setSearch] = useState('')
    const [lazyState, setlazyState] = useState(initialLazyState);
    const [expandedRows, setExpandedRows] = useState(null);
    const axiosApi = useAxiosPrivate();
    const items = Array.from({ length: 5 }, (v, i) => i);

    const delay = 300;
    const [headerFilter, setHeaderFilter] = useState(null);
    const [isExport, setIsExport] = useState(false);

    const handleConfirm = async ({ data, exportHeader }) => {
        const headers = data.fields;

        const filteredHeaders = exportHeader.filter(header =>
            headers.includes(header.key)
        );
        const result = {
            headers: filteredHeaders,
            isAnonymous: data.isAnonymous
        }

        setIsExport(true)
        setHeaderFilter(result)
        // setIsExport(false)
    }

    const coordinates = (data) => {
        return data.uco_unit.external_box_latitude + ', ' + data.uco_unit.external_box_longitude;
    }
    const boxNames = (data) => {
        return data.uco_unit.external_box_code;
    }
    const ucoUnitId = (data) => {
        return <TrackingModal rowData={data} unitIdParam={data.uco_unit_code} />
    }

    const columns = [
        { field: 'uco_unit.external_transaction_code', header: 'Transaction ID', body: ucoUnitId },
        { field: 'box_name', header: 'Origin Box ID', body: boxNames, },
        { field: 'uco_unit.fullname', header: 'User Full Name' },
        { field: 'uco_unit.username', header: 'Username' },
        { field: 'uco_unit.weight', header: 'Accepted Weight (kg)' },
        { field: 'uco_unit.external_transaction_enddate', header: 'End Time' },
        { field: 'uco_unit.address', header: 'Address' },
        // { field: 'coordinates', header: 'Coordinates',body:coordinates },
        { field: 'uco_unit.postcode', header: 'Post Code' },
        { field: 'uco_unit.city', header: 'City' },
        { field: 'uco_unit.country', header: 'Country' },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }
    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }


    const getData = async () => {
        try {
            setLoading(true);
            const response = await axiosApi.get(`/api/erp/inventory/get-uco-units/by-order?order_code=${order_code}&rows=${lazyState.config.rows}&skip=${lazyState.config.first}&searchText=${lazyState.searchText}&is_anonymize=true`);
            const result = response.data.results;
            setDatas(result);
            setTotalRecords(response.data.total_records || 0)
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
            setLoading(false);
            console.log(error);
        }
    }


    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    useEffect(() => {
        if (modal_center) {
            getData()
        };
    }, [modal_center, lazyState]);

    return (
        <>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody>
                            <Row className="align-items-center mb-2">
                                <Col md={6} className="d-flex flex-row align-items-center gap-3">

                                    <h4 className="card-title mb-0">UCO Units</h4>
                                </Col>
                                <Col md={6}>
                                    <span className="p-input-icon-left float-start float-md-end">
                                        <i className="pi pi-search" />
                                        <InputText value={search} onChange={onSearchTextChange} placeholder="ID" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                    </span>
                                </Col>
                            </Row>
                            <DataTable
                                lazy
                                value={loading ? items : datas}
                                stripedRows
                                paginator
                                rows={lazyState.config.rows}
                                rowsPerPageOptions={ROWS_PER_PAGE}
                                size={'normal'}
                                onPage={onPage}
                                totalRecords={totalRecords}
                                first={lazyState.config.first}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"

                                // expandedRows={loading ? null : expandedRows}
                                // rowExpansionTemplate={rowExpansionTemplate}
                                // onRowToggle={onRowToggle}
                                dataKey={loading ? '' : 'id'}
                            >
                                {/* <Column expander={true} style={{ width: '3rem' }} /> */}
                                {columns.map((col, i) => (
                                    <Column key={col.field} field={col.field} header={col.header} body={loading ? <Skeleton> </Skeleton> : col.body} />
                                ))}
                            </DataTable>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default DataTables
