import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import {
    currencyFormatter,
} from "helpers/functions"
const FillAlertConfirmationModal = ({ isOpen, toggle, onConfirm, permissions, data, rawData }) => {
    let sortedRawData = rawData.alert_fill_level_admin_role?JSON.parse(rawData.alert_fill_level_admin_role).slice().sort():[];
    let sortedData = JSON.parse(data.alert_fill_level_admin_role).slice().sort();

    const arraysAreEqual = JSON.stringify(sortedRawData) === JSON.stringify(sortedData);
    const findTitleById = (id) => {
        if (permissions.length === 0) return 'Title not found';
        const menu = permissions.find(menuItem => menuItem.key === id);
        if (menu) {
            return menu.title;
        }

        return 'Title not found';
    };
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Confirm Your Settings</ModalHeader>
            <ModalBody>
                {rawData.fill_level_percentage == data.fill_level_percentage
                    ? <p> Minimum Fill Level (%) : <b>{data.fill_level_percentage || 0}</b></p>
                    : <p> Minimum Fill Level (%) : <s className='text-danger'>{rawData.fill_level_percentage}</s> → <span className='text-primary'>{data.fill_level_percentage}</span></p>
                }
                {rawData.slop_fill_level_percentage == data.slop_fill_level_percentage
                    ? <p> Minimum Slop Fill Level (%) : <b>{data.slop_fill_level_percentage || 0}</b></p>
                    : <p> Minimum Slop Fill Level (%) : <s className='text-danger'>{rawData.slop_fill_level_percentage}</s> → <span className='text-primary'>{data.slop_fill_level_percentage}</span></p>
                }
                {rawData.fill_level_notification_hour_interval == data.fill_level_notification_hour_interval
                    ? <p> Notification Interval (in Hours) : <b>{data.fill_level_notification_hour_interval || 0}</b></p>
                    : <p> Notification Interval (in Hours) : <s className='text-danger'>{rawData.fill_level_notification_hour_interval}</s> → <span className='text-primary'>{data.fill_level_notification_hour_interval}</span></p>
                }
                {arraysAreEqual
                    ? <p> Alert Email List :
                        <b>
                            <ul>
                                {sortedRawData.map((item, index) => (
                                    <li key={index}>{findTitleById(item)}</li>
                                ))}
                            </ul>
                        </b>
                    </p>
                    : <p> Alert Emails :
                        <div className='d-flex flex-row gap-2'>
                            <s className='text-danger'>
                                <ul>
                                    {sortedRawData.map((item, index) => (
                                        <li key={index}>{findTitleById(item)}</li>
                                    ))}
                                </ul>
                            </s> →
                            <div className='text-primary'>
                                <ul>
                                    {sortedData.map((item, index) => (
                                        <li key={index}>{findTitleById(item)}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                    </p>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onConfirm}>Submit</Button>{' '}
                {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
            </ModalFooter>
        </Modal>
    )
}

export default FillAlertConfirmationModal
